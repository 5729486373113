<template>
  <div class="max-w-4xl mx-auto">
    <h1 class="text-3xl font-bold mb-6">API Keys</h1>
    <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <h2 class="text-xl font-semibold mb-4">Shopify API Key</h2>
      <p class="mb-4">To get your Shopify API key:</p>
      <ol class="list-decimal list-inside mb-4">
        <li>Log in to your Shopify admin panel</li>
        <li>Go to Apps > Manage private apps</li>
        <li>Click "Create new private app"</li>
        <li>Fill in the app details and set the required permissions</li>
        <li>Click "Save" and copy the API key</li>
      </ol>
      <input v-model="shopifyKey" type="password" placeholder="Enter Shopify API Key" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
    </div>
    <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <h2 class="text-xl font-semibold mb-4">Klaviyo API Key</h2>
      <p class="mb-4">To get your Klaviyo API key:</p>
      <ol class="list-decimal list-inside mb-4">
        <li>Log in to your Klaviyo account</li>
        <li>Go to Account > Settings > API Keys</li>
        <li>Click "Create API Key"</li>
        <li>Name your key and select the required permissions</li>
        <li>Click "Create" and copy the API key</li>
      </ol>
      <input v-model="klaviyoKey" type="password" placeholder="Enter Klaviyo API Key" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
    </div>
    <button @click="saveKeys" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Save Keys</button>
  </div>
</template>

<script>
export default {
  name: 'APIKeys',
  data() {
    return {
      shopifyKey: '',
      klaviyoKey: ''
    }
  },
  methods: {
    async saveKeys() {
      try {
        const response = await fetch('/api/save-keys', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            shopifyKey: this.shopifyKey,
            klaviyoKey: this.klaviyoKey,
          }),
        });
        if (response.ok) {
          alert('API keys saved successfully');
          this.shopifyKey = '';
          this.klaviyoKey = '';
        } else {
          throw new Error('Failed to save API keys');
        }
      } catch (error) {
        console.error('Error saving API keys:', error);
        alert('Failed to save API keys. Please try again.');
      }
    }
  }
}
</script>