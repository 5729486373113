import { createStore } from 'vuex';
import { auth } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';

export default createStore({
  state: {
    user: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    clearUser(state) {
      state.user = null;
    }
  },
  actions: {
    login({ commit }, user) {
      commit('setUser', user);
    },
    logout({ commit }) {
      commit('clearUser');
    },
    async signup({ commit }, { email, password }) {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        commit('setUser', user);
        
        // We'll handle the Facebook Pixel event in the component
        return user;
      } catch (error) {
        console.error('Error during signup:', error);
        throw error;
      }
    }
  },
  getters: {
    user: state => state.user,
  }
});