<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <h1 class="text-3xl font-bold mb-6">Dashboard</h1>
    <div v-if="!accountActivated" class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6" role="alert">
      <p class="font-bold">Account not activated</p>
      <p>Please <router-link to="/schedule-meeting" class="underline">schedule a meeting</router-link> to activate your account.</p>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
      <div class="bg-white shadow rounded-lg p-6">
        <h2 class="text-xl font-semibold mb-4">Events Sent</h2>
        <canvas ref="eventsChart"></canvas>
      </div>
      <div class="bg-white shadow rounded-lg p-6">
        <h2 class="text-xl font-semibold mb-4">Daily Events</h2>
        <canvas ref="dailyEventsChart"></canvas>
      </div>
    </div>
    <div class="bg-white shadow rounded-lg p-6 mb-6">
      <h2 class="text-xl font-semibold mb-4">Metrics</h2>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div class="bg-gray-100 p-4 rounded">
          <h3 class="text-lg font-medium">Total Events</h3>
          <p class="text-3xl font-bold">{{ metrics.totalEvents }}</p>
        </div>
        <div class="bg-gray-100 p-4 rounded">
          <h3 class="text-lg font-medium">Deduplicated Events</h3>
          <p class="text-3xl font-bold">{{ metrics.deduplicatedEvents }}</p>
        </div>
        <div class="bg-gray-100 p-4 rounded">
          <h3 class="text-lg font-medium">Deduplication Rate</h3>
          <p class="text-3xl font-bold">{{ metrics.deduplicationRate }}%</p>
        </div>
      </div>
    </div>
    <div class="bg-white shadow rounded-lg p-6">
      <h2 class="text-xl font-semibold mb-4">Recent Events</h2>
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Event Type</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Timestamp</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="event in recentEvents" :key="event.id">
            <td class="px-6 py-4 whitespace-nowrap">{{ event.type }}</td>
            <td class="px-6 py-4 whitespace-nowrap">{{ event.timestamp }}</td>
            <td class="px-6 py-4 whitespace-nowrap">
              <span :class="{'text-green-600': event.status === 'Success', 'text-red-600': event.status === 'Failed'}">
                {{ event.status }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="!accountActivated" class="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div class="bg-white p-8 rounded-lg shadow-lg text-center">
        <h2 class="text-2xl font-bold mb-4">Activate Your Account</h2>
        <p class="mb-6">Please schedule a meeting to activate your account.</p>
        <router-link to="/schedule-meeting" class="inline-block bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700">Schedule Meeting</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import Chart from 'chart.js/auto';

export default {
  name: 'DashboardPage',
  setup() {
    const eventsChart = ref(null);
    const dailyEventsChart = ref(null);
    const accountActivated = ref(false); // This should be fetched from your backend

    const metrics = ref({
      totalEvents: 15000,
      deduplicatedEvents: 14500,
      deduplicationRate: 10.5
    });

    const recentEvents = ref([
      { id: 1, type: 'Purchase', timestamp: '2023-05-01 14:30:00', status: 'Success' },
      { id: 2, type: 'Add to Cart', timestamp: '2023-05-01 14:25:00', status: 'Success' },
      { id: 3, type: 'Page View', timestamp: '2023-05-01 14:20:00', status: 'Failed' },
      // Add more demo data as needed
    ]);

    onMounted(() => {
      // Events Sent Chart
      new Chart(eventsChart.value, {
        type: 'line',
        data: {
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
          datasets: [
            {
              label: 'Events Sent',
              data: [1200, 1900, 3000, 5000, 8000, 15000],
              borderColor: 'rgb(75, 192, 192)',
              tension: 0.1
            },
            {
              label: 'Deduplicated Events',
              data: [1150, 1800, 2900, 4800, 7700, 14500],
              borderColor: 'rgb(255, 99, 132)',
              tension: 0.1
            }
          ]
        }
      });

      // Daily Events Chart
      new Chart(dailyEventsChart.value, {
        type: 'bar',
        data: {
          labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          datasets: [{
            label: 'Daily Events',
            data: [500, 600, 700, 800, 900, 400, 300],
            backgroundColor: 'rgba(75, 192, 192, 0.6)'
          }]
        }
      });
    });

    return {
      eventsChart,
      dailyEventsChart,
      accountActivated,
      metrics,
      recentEvents
    };
  }
}
</script>