<template>
  <div>
    <h2 class="text-2xl font-bold mb-4">Schedule a Meeting</h2>
    <!-- Calendly inline widget begin -->
    <div class="calendly-inline-widget" data-url="https://calendly.com/sunbek/first-encounter" style="min-width:320px;height:700px;"></div>
    <!-- Calendly inline widget end -->
  </div>
</template>

<script>
export default {
  name: 'ScheduleMeetingPage',
  mounted() {
    // Load Calendly script
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.head.appendChild(script);
  }
}
</script>