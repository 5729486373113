import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './index.css';
import { VueFbq } from 'vue3-facebook-pixel';

const app = createApp(App);

// Initialize Facebook Pixel
app.use(VueFbq, {
  pixelId: '299251946036121',
  debug: true // optional, enables console logging
});

app.use(store).use(router).mount('#app');