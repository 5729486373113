<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
      <div>
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Create a new account
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          Already have an account? 
          <router-link to="/login" class="font-medium text-indigo-600 hover:text-indigo-500">
            Sign in
          </router-link>
        </p>
      </div>
      <form class="mt-8 space-y-6" @submit.prevent="handleSubmit">
        <div v-if="errorMessage" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <span class="block sm:inline">{{ errorMessage }}</span>
        </div>
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="email-address" class="sr-only">Email address</label>
            <input id="email-address" v-model="email" name="email" type="email" autocomplete="email" required
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Email address">
          </div>
          <div>
            <label for="password" class="sr-only">Password</label>
            <input id="password" v-model="password" name="password" type="password" autocomplete="new-password" required
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Password">
          </div>
          <div>
            <label for="confirm-password" class="sr-only">Confirm Password</label>
            <input id="confirm-password" v-model="confirmPassword" name="confirm-password" type="password" autocomplete="new-password" required
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Confirm Password">
          </div>
        </div>
        <div>
          <button type="submit"
            class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            :disabled="!isFormValid">
            Sign up
          </button>
        </div>
        <div class="relative flex justify-center text-sm">
          <span class="px-2 bg-gray-50 text-gray-500">or continue with</span>
        </div>
        <div>
          <button @click="signUpWithGoogle" type="button"
            class="group relative w-full flex justify-center py-2 px-4 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <!-- Google icon SVG here -->
            </span>
            Sign up with Google
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { auth } from '../firebase';
import { 
  signInWithPopup, 
  GoogleAuthProvider
} from 'firebase/auth';
import { useFbq } from 'vue3-facebook-pixel';

export default {
  name: 'SignupPage',
  setup() {
    const email = ref('');
    const password = ref('');
    const confirmPassword = ref('');
    const errorMessage = ref('');
    const store = useStore();
    const router = useRouter();
    const fbq = useFbq();

    const isFormValid = computed(() => {
      return email.value && password.value && confirmPassword.value && password.value === confirmPassword.value;
    });

    const signUpWithGoogle = async () => {
      const provider = new GoogleAuthProvider();
      try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        await store.dispatch('login', user);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('loginTime', Date.now());
        if (fbq) {
          fbq.event('CompleteRegistration');
        }
        router.push('/');
      } catch (error) {
        console.error('Error signing up with Google:', error);
        errorMessage.value = error.message;
      }
    };

    const handleSubmit = async () => {
      if (!isFormValid.value) {
        errorMessage.value = 'Please fill in all fields correctly.';
        return;
      }

      try {
        const user = await store.dispatch('signup', { email: email.value, password: password.value });
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('loginTime', Date.now());
        if (fbq) {
          fbq.event('CompleteRegistration');
        }
        router.push('/');
      } catch (error) {
        console.error('Error signing up:', error);
        errorMessage.value = error.message;
      }
    };

    return {
      email,
      password,
      confirmPassword,
      errorMessage,
      isFormValid,
      signUpWithGoogle,
      handleSubmit
    };
  }
}
</script>