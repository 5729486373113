<template>
    <div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div class="max-w-md w-full space-y-8">
        <div>
          <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
          <p class="mt-2 text-center text-sm text-gray-600">
            Or 
            <router-link to="/signup" class="font-medium text-indigo-600 hover:text-indigo-500">
              sign up for a new account
            </router-link>
          </p>
        </div>
        <form class="mt-8 space-y-6" @submit.prevent="handleSubmit">
          <div v-if="errorMessage" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <span class="block sm:inline">{{ errorMessage }}</span>
          </div>
          <div class="rounded-md shadow-sm -space-y-px">
            <div>
              <label for="email-address" class="sr-only">Email address</label>
              <input id="email-address" v-model="email" name="email" type="email" autocomplete="email" required
                class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Email address">
            </div>
            <div>
              <label for="password" class="sr-only">Password</label>
              <input id="password" v-model="password" name="password" type="password" autocomplete="current-password" required
                class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Password">
            </div>
          </div>
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <input id="remember-me" name="remember-me" type="checkbox"
                class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded">
              <label for="remember-me" class="ml-2 block text-sm text-gray-900">
                Remember me
              </label>
            </div>
            <div class="text-sm">
              <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
                Forgot your password?
              </a>
            </div>
          </div>
          <div>
            <button type="submit"
              class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              :disabled="!isFormValid">
              Sign in
            </button>
          </div>
          <div class="relative flex justify-center text-sm">
            <span class="px-2 bg-gray-50 text-gray-500">or continue with</span>
          </div>
          <div>
            <button @click="signInWithGoogle" type="button"
              class="group relative w-full flex justify-center py-2 px-4 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                <!-- Google icon SVG here -->
              </span>
              Sign in with Google
            </button>
          </div>
        </form>
      </div>
    </div>
  </template>
<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { auth } from '../firebase';
import { 
  signInWithPopup, 
  GoogleAuthProvider, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword 
} from 'firebase/auth';

export default {
  name: 'LoginPage',
  setup() {
    const isLogin = ref(true);
    const email = ref('');
    const password = ref('');
    const confirmPassword = ref('');
    const errorMessage = ref('');
    const store = useStore();
    const router = useRouter();

    const isFormValid = computed(() => {
      if (!email.value || !password.value) return false;
      if (!isLogin.value && password.value !== confirmPassword.value) return false;
      return true;
    });

    const signInWithGoogle = async () => {
      const provider = new GoogleAuthProvider();
      try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        await store.dispatch('login', user);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('loginTime', Date.now());
        router.push('/');
      } catch (error) {
        console.error('Error signing in with Google:', error);
        errorMessage.value = error.message;
      }
    };

    const handleSubmit = async () => {
  console.log('handleSubmit called');
  console.log('Email:', email.value);
  console.log('Password:', password.value);
  console.log('Confirm Password:', confirmPassword.value);
  console.log('Is Login:', isLogin.value);

  if (!isFormValid.value) {
    console.log('Form is not valid');
    errorMessage.value = 'Please fill in all fields correctly.';
    return;
  }

  try {
    let user;
    if (isLogin.value) {
      console.log('Attempting to sign in');
      const result = await signInWithEmailAndPassword(auth, email.value, password.value);
      user = result.user;
    } else {
      console.log('Attempting to sign up');
      const result = await createUserWithEmailAndPassword(auth, email.value, password.value);
      user = result.user;
    }
    console.log('Authentication successful', user);
    await store.dispatch('login', user);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('loginTime', Date.now());
    router.push('/');
  } catch (error) {
    console.error('Authentication error:', error);
    errorMessage.value = error.message;
  }
};

    return {
      isLogin,
      email,
      password,
      confirmPassword,
      errorMessage,
      isFormValid,
      signInWithGoogle,
      handleSubmit
    };
  }
}
</script>

<style scoped>
/* Add any additional styles here */
</style>