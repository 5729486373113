import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

const firebaseConfig = {
  // Your Firebase configuration object
  // Replace this with your actual Firebase config
  apiKey: "AIzaSyCnxgAh3OX5tCSdwwz5K4S6VsegeqC0jrc",
  authDomain: "serverside-d7ce8.firebaseapp.com",
  projectId: "serverside-d7ce8",
  storageBucket: "serverside-d7ce8.appspot.com",
  messagingSenderId: "1047664478810",
  appId: "1:1047664478810:web:14b6f8fe2200b9160ce663",
  measurementId: "G-227VQWQM4V"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    return result.user;
  } catch (error) {
    console.error("Error signing in with Google", error);
    throw error;
  }
};

export { auth, signInWithGoogle };