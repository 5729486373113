<template>
  <div class="flex flex-col h-screen bg-gray-100">
    <!-- Header (Mobile and Desktop) -->
    <div v-if="showMenu" class="bg-gray-800 text-white p-4 flex justify-between items-center fixed top-0 left-0 right-0 z-50">
      <button @click="toggleSidebar" class="text-white p-2 w-10 h-10 flex items-center justify-center md:hidden">
        <svg v-if="!isSidebarOpen" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <img src="/soloflow-logo.png" alt="SoloFlow Logo" class="h-8 w-auto object-contain mx-auto md:mx-0">
      <div class="w-10 md:hidden"></div>
    </div>

    <div class="flex flex-1 overflow-hidden" :class="{ 'pt-16': showMenu }">
      <!-- Sidebar -->
      <div v-if="showMenu" :class="['md:relative md:flex md:flex-col md:w-64 bg-gray-800 transition-all duration-300 ease-in-out',
                    isSidebarOpen ? 'fixed inset-0 z-40 pt-16' : 'hidden md:flex']">
        <ul class="flex flex-col py-4">
          <li>
            <router-link to="/" class="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-300 hover:text-white" @click="toggleSidebar">
              <span class="inline-flex items-center justify-center h-12 w-12 text-lg text-gray-400"><i class="bx bx-home"></i></span>
              <span class="text-sm font-medium">Dashboard</span>
            </router-link>
          </li>
          <li>
            <router-link to="/schedule-meeting" class="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-300 hover:text-white" @click="toggleSidebar">
              <span class="inline-flex items-center justify-center h-12 w-12 text-lg text-gray-400"><i class="bx bx-calendar"></i></span>
              <span class="text-sm font-medium">Schedule Meeting</span>
            </router-link>
          </li>
          <li>
            <router-link to="/api-keys" class="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-300 hover:text-white" @click="toggleSidebar">
              <span class="inline-flex items-center justify-center h-12 w-12 text-lg text-gray-400"><i class="bx bx-key"></i></span>
              <span class="text-sm font-medium">API Keys</span>
            </router-link>
          </li>
          <li>
            <router-link to="/billing" class="flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-300 hover:text-white" @click="toggleSidebar">
              <span class="inline-flex items-center justify-center h-12 w-12 text-lg text-gray-400"><i class="bx bx-credit-card"></i></span>
              <span class="text-sm font-medium">Billing</span>
            </router-link>
          </li>
          <li>
            <button @click="confirmLogout(); toggleSidebar();" class="flex flex-row items-center h-12 w-full transform hover:translate-x-2 transition-transform ease-in duration-200 text-gray-300 hover:text-white">
              <span class="inline-flex items-center justify-center h-12 w-12 text-lg text-gray-400"><i class="bx bx-log-out"></i></span>
              <span class="text-sm font-medium">Logout</span>
            </button>
          </li>
        </ul>
      </div>

      <!-- Content -->
      <div class="flex-1 overflow-auto p-4 md:p-8">
        <h1 v-if="showMenu" class="text-3xl font-bold mb-6 text-gray-800 hidden md:block">{{ pageTitle }}</h1>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { auth } from './firebase';
import { signOut } from 'firebase/auth';
import { useFbq } from 'vue3-facebook-pixel';

export default {
  name: 'App',
  setup() {
    const fbq = useFbq();
    return { fbq };
  },
  data() {
    return {
      isSidebarOpen: false,
    };
  },
  computed: {
    ...mapState(['user']),
    pageTitle() {
      // This is a basic implementation. You might want to customize it based on your routes
      return this.$route.name || '';
    },
    showMenu() {
      return this.user && !['login', 'signup'].includes(this.$route.name);
    }
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
      // Prevent scrolling on body when sidebar is open on mobile
      document.body.style.overflow = this.isSidebarOpen ? 'hidden' : '';
    },
    confirmLogout() {
      if (confirm('Are you sure you want to log out?')) {
        this.logout();
      }
    },
    async logout() {
      try {
        await signOut(auth);
        this.$store.dispatch('logout');
        localStorage.removeItem('user');
        localStorage.removeItem('loginTime');
        console.log('User data removed from local storage'); // Debugging line
        this.$router.push('/login');
      } catch (error) {
        console.error('Error logging out:', error);
      }
    }
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem('user'));
    const loginTime = localStorage.getItem('loginTime');
    console.log('User from local storage on mount:', user); // Debugging line
    console.log('Login time from local storage on mount:', loginTime); // Debugging line
    if (user && loginTime && (Date.now() - loginTime) < 86400000) { // 1 day in milliseconds
      this.$store.dispatch('login', user);
    }

    // Debug Facebook Pixel
    if (this.fbq) {
      console.log('Facebook Pixel is loaded and initialized');
    } else {
      console.warn('Facebook Pixel is not loaded or initialized');
    }
  }
}
</script>

<style>
body {
  overflow-x: hidden;
}

@media (min-width: 768px) {
  body {
    overflow: auto !important;
  }
}

/* Add these new styles */
.router-link-active {
  background-color: rgba(255, 255, 255, 0.1);
  color: white !important;
}

.router-link-active i {
  color: white !important;
}

/* Add this new style for the logo */
.h-8.w-auto.object-contain {
  max-width: 200px; /* Adjust this value as needed */
}
</style>
