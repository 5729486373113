<template>
  <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
    <h1 class="text-3xl font-bold mb-6">Billing</h1>
    <p class="mb-8 text-lg">Choose the plan that best fits your needs. All plans include:</p>
    <ul class="list-disc list-inside mb-8 text-lg">
      <li>Expert Incident Response</li>
      <li>Proactive Monitoring</li>
      <li>One flow instead of two for both legacy and server-side events</li>
      <li>Reduced risk of errors</li>
      <li>Simplified A/B testing</li>
    </ul>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
      <div class="bg-white shadow-lg rounded-lg overflow-hidden">
        <div class="px-6 py-8 bg-indigo-50 border-b">
          <h2 class="text-2xl font-bold text-center text-indigo-800">Starter</h2>
          <p class="mt-4 text-center text-indigo-600">Up to 1,000 monthly orders</p>
          <p class="mt-4 text-5xl font-bold text-center text-indigo-800">$50</p>
          <p class="mt-1 text-center text-indigo-600">/month</p>
        </div>
        <div class="px-6 py-8">
          <ul class="text-sm text-gray-600 mb-6">
            <li class="mb-2 flex items-center">
              <svg class="h-5 w-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
              Easy 10-minute setup
            </li>
            <li class="mb-2 flex items-center">
              <svg class="h-5 w-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
              Works with legacy and server-side events
            </li>
            <li class="flex items-center">
              <svg class="h-5 w-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
              Basic support
            </li>
          </ul>
          <button @click="subscribe('starter')" class="w-full bg-indigo-600 text-white rounded-md py-2 px-4 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Subscribe to Starter</button>
        </div>
      </div>
      <div class="bg-white shadow-lg rounded-lg overflow-hidden">
        <div class="px-6 py-8 bg-indigo-100 border-b">
          <h2 class="text-2xl font-bold text-center text-indigo-800">Growth</h2>
          <p class="mt-4 text-center text-indigo-600">Up to 5,000 monthly orders</p>
          <p class="mt-4 text-5xl font-bold text-center text-indigo-800">$200</p>
          <p class="mt-1 text-center text-indigo-600">/month</p>
        </div>
        <div class="px-6 py-8">
          <ul class="text-sm text-gray-600 mb-6">
            <li class="mb-2 flex items-center">
              <svg class="h-5 w-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
              All Starter features
            </li>
            <li class="mb-2 flex items-center">
              <svg class="h-5 w-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
              Priority support
            </li>
            <li class="flex items-center">
              <svg class="h-5 w-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
              Advanced analytics
            </li>
          </ul>
          <button @click="subscribe('growth')" class="w-full bg-indigo-600 text-white rounded-md py-2 px-4 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Subscribe to Growth</button>
        </div>
      </div>
      <div class="bg-white shadow-lg rounded-lg overflow-hidden">
        <div class="px-6 py-8 bg-indigo-200 border-b">
          <h2 class="text-2xl font-bold text-center text-indigo-800">Enterprise</h2>
          <p class="mt-4 text-center text-indigo-600">Custom solutions</p>
          <p class="mt-4 text-3xl font-bold text-center text-indigo-800">Contact Us</p>
          <p class="mt-1 text-center text-indigo-600">for pricing</p>
        </div>
        <div class="px-6 py-8">
          <ul class="text-sm text-gray-600 mb-6">
            <li class="mb-2 flex items-center">
              <svg class="h-5 w-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
              All Growth features
            </li>
            <li class="mb-2 flex items-center">
              <svg class="h-5 w-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
              Dedicated account manager
            </li>
            <li class="flex items-center">
              <svg class="h-5 w-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
              Custom integrations
            </li>
          </ul>
          <button @click="subscribe('enterprise')" class="w-full bg-indigo-600 text-white rounded-md py-2 px-4 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Contact Sales</button>
        </div>
      </div>
    </div>
    <div class="mt-12">
      <h2 class="text-2xl font-bold mb-4">Frequently Asked Questions</h2>
      <div class="space-y-4">
        <div>
          <h3 class="text-lg font-semibold">How difficult is it to integrate your solution with my current setup?</h3>
          <p>It's incredibly easy! Most users are up and running in under 10 minutes.</p>
        </div>
        <div>
          <h3 class="text-lg font-semibold">Will this work with both legacy and server-side events?</h3>
          <p>Absolutely. Our solution is designed to handle both seamlessly, allowing you to manage everything with one flow.</p>
        </div>
        <div>
          <h3 class="text-lg font-semibold">What if I need help setting it up?</h3>
          <p>Our support team is always ready to assist you. We offer comprehensive documentation and personalized support to ensure a smooth setup process.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BillingPage',
  methods: {
    subscribe(plan) {
      if (plan === 'enterprise') {
        this.$router.push('/schedule-meeting');
      } else {
        let checkoutUrl;
        switch (plan) {
          case 'starter':
            checkoutUrl = 'https://buy.stripe.com/test_3cs2aD8wL5BC73G145';
            break;
          case 'growth':
            checkoutUrl = 'https://buy.stripe.com/test_fZebLd4gv7JKds43cc';
            break;
          default:
            console.error('Invalid plan selected');
            return;
        }
        window.open(checkoutUrl, '_blank');
      }
    }
  }
}
</script>