import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import DashboardPage from '../components/Dashboard.vue';
import LoginPage from '../components/Login.vue';
import APIKeys from '../components/APIKeys.vue';
import BillingPage from '../components/Billing.vue';
import ScheduleMeetingPage from '../components/ScheduleMeeting.vue';
import Signup from '../components/Signup.vue';

const routes = [
  { path: '/', component: DashboardPage, meta: { requiresAuth: true } },
  { path: '/login', component: LoginPage },
  { path: '/api-keys', component: APIKeys, meta: { requiresAuth: true } },
  { path: '/billing', component: BillingPage, meta: { requiresAuth: true } },
  { path: '/schedule-meeting', component: ScheduleMeetingPage, meta: { requiresAuth: true } },
  {
    path: '/signup',
    name: 'signup',
    component: Signup
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = store.state.user;
  if (requiresAuth && !isAuthenticated) {
    next('/login');
  } else {
    next();
  }
});

export default router;